import axios from '../http/axios';

class Auth {
  constructor() {
    this.token = window.localStorage.getItem('token');
    let userData = window.localStorage.getItem('user');
    this.user = userData ? JSON.parse(userData) : null;

    if (this.token) {
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.token;
    }
  }
  login(token, user) {
    if (!user.role || user.role !== 'admin') {
      this.logout();

      return;
    }

    window.localStorage.setItem('token', token);
    window.localStorage.setItem('user', JSON.stringify(user));
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;

    this.token = token;
    this.user = user;
  }
  check() {
    return !!this.token;
  }
  logout() {
    window.localStorage.removeItem('token');
    window.localStorage.removeItem('user');
    this.user = null;
  }
}
export default new Auth();
