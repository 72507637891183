import Vue from 'vue';

import {
  BBadge,
  BButton,
  BCard,
  BCardTitle,
  BCardText,
  BCol,
  BDropdownItem,
  BForm,
  BFormCheckbox,
  BFormFile,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BImg,
  BInputGroup,
  BInputGroupAppend,
  BLink,
  BNavbarNav,
  BNavItemDropdown,
  BRow,
  BTable,
  BIconCloudUploadFill,
  BIconEdit,
  BListGroup,
  BListGroupItem,
  BIconTrash,
  BIconX,
  BCollapse,
} from 'bootstrap-vue';

import { ValidationObserver, ValidationProvider } from 'vee-validate';

import vSelect from 'vue-select';

import FeatherIcon from '@core/components/feather-icon/FeatherIcon.vue';

Vue.component('b-badge', BBadge);
Vue.component('b-button', BButton);
Vue.component('b-card', BCard);
Vue.component('b-card-text', BCardText);
Vue.component('b-col', BCol);
Vue.component('b-dropdown-item', BDropdownItem);
Vue.component('b-form', BForm);
Vue.component('b-form-checkbox', BFormCheckbox);
Vue.component('b-form-file', BFormFile);
Vue.component('b-form-group', BFormGroup);
Vue.component('b-form-input', BFormInput);
Vue.component('b-form-textarea', BFormTextarea);
Vue.component('b-img', BImg);
Vue.component('b-input-group', BInputGroup);
Vue.component('b-input-group-append', BInputGroupAppend);
Vue.component('b-link', BLink);
Vue.component('b-nav-item-dropdown', BNavItemDropdown);
Vue.component('b-navbar-nav', BNavbarNav);
Vue.component('b-row', BRow);
Vue.component('b-table', BTable);
Vue.component('b-icon-cloud-upload-fill', BIconCloudUploadFill);
Vue.component('b-list-group', BListGroup);
Vue.component('b-list-group-item', BListGroupItem);
Vue.component('b-icon-trash', BIconTrash);
Vue.component('b-icon-x', BIconX);
Vue.component('b-icon-edit', BIconEdit);
Vue.component('b-collapse', BCollapse);
Vue.component('b-card-title', BCardTitle);

Vue.component('validation-observer', ValidationObserver);
Vue.component('validation-provider', ValidationProvider);

Vue.component('v-select', vSelect);

Vue.component(FeatherIcon.name, FeatherIcon);
