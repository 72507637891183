import { extend } from 'vee-validate';
import {
  alpha,
  alpha_num,
  alpha_dash,
  alpha_spaces,
  decimal,
  confirmed,
  email,
  max,
  max_value,
  min,
  numeric,
  password,
  positive,
  regex,
  required,
  size,
} from 'vee-validate/dist/rules';

extend('required', {
  ...required,
  message: 'Pole {_field_} jest wymagane',
});

extend('min', {
  validate(value, args) {
    return value.length >= args.length;
  },
  params: ['length'],
  message: 'Pole {_field_} musi mieć co najmniej {length} znaków',
});

extend('max', {
  validate(value, args) {
    return value.length <= args.length;
  },
  params: ['length'],
  message: 'Pole {_field_} może mieć maksymalnie {length} znaków',
});

extend('email', {
  ...email,
  message: 'Pole {_field_} musi być poprawnym adresem email',
});

extend('password', {
  ...password,
  validate(value) {
    var strongRegex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})');

    return strongRegex.test(value);
  },
  message:
    'Pole {_field_} musi mieć co najmniej 8 znaków, zawierać co najmniej jedną wielką literę, jedną małą literę i jedną cyfrę',
});

extend('confirmed', {
  ...confirmed,
  message: 'Pole {_field_} musi być takie samo jak pole {target}',
});

extend('positive', {
  ...positive,
  validate(value) {
    return value > 0;
  },
  message: 'Pole {_field_} musi być liczbą dodatnią',
});

extend('positive_or_zero', {
  ...positive,
  validate(value) {
    return value >= 0;
  },
  message: 'Pole {_field_} musi być liczbą dodatnią lub zerem',
});

extend('decimal', {
  ...decimal,
  validate(value, args) {
    return value.toString().match(new RegExp(`^-?\\d*(\\.\\d{0,${args.decimals}})?$`));
  },
  params: ['decimals'],
  message: 'Pole {_field_} musi być liczbą zmiennoprzecinkową o maksymalnie {decimals} miejscach po przecinku',
});

extend('phone', {
  validate(value) {
    return value.match(/^[0-9-+() ]+$/);
  },
  message: 'Pole {_field_} musi być poprawnym numerem telefonu (bez liter)',
});

extend('numeric', {
  ...numeric,
  message: 'Pole {_field_} musi się składać z cyfr',
});

extend('url', {
  validate(value) {
    return value.match(/^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/);
  },
  message: 'Pole {_field_} musi być poprawnym adresem URL',
});

extend('max_value', {
  ...max_value,
  message: 'Pole {_field_} musi być mniejsze lub równe {max}',
});

extend('alpha_num', {
  ...alpha_num,
  message: 'Pole {_field_} może zawierać tylko pełne liczby',
});

extend('alpha_dash', {
  ...alpha_dash,
  message: 'Pole {_field_} nie może zawierać spacji ani znaków specjalnych. Użyj myślnika lub podkreślenia',
});

extend('alpha_spaces', {
  ...alpha_spaces,
  message: 'Pole {_field_} może zawierać tylko litery i spacje',
});

extend('alpha', {
  ...alpha,
  message: 'Pole {_field_} może zawierać tylko litery',
});

extend('size', {
  ...size,
  message: 'Plik {_field_} musi mieć maksymalny rozmiar {size}KB',
});
